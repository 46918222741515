export default {
  Customer: 'Khách hàng',
  textShowCustomer: 'khách hàng',
  textBtnCreate: 'Thêm mới',
  addNewMemberCard: 'Thêm thẻ thành viên',
  deleteMemberCard: 'Xóa thẻ thành viên',
  noMemberShipCard: 'Không có thẻ thành viên nào',
  placeholderSearch: 'Tên, email, số điện thoại, mã khách hàng ...',
  checkbox: '',
  code: 'Mã khách hàng',
  placeholderCode: 'Nhập mã khách hàng',
  identifyNumber: 'CCCD',
  tabInfo: 'Thông tin khách hàng',
  created: 'Thời gian tạo',
  updated: 'Thời gian sửa đổi',
  memberCards: {
    cardNumber: 'Số thẻ thành viên',
    airline: 'Hãng hàng không',
    supplierCode: 'Mã cung cấp',
  },
  socialAccounts: 'Mạng xã hội',
  STT: 'STT',
  Customer_First_Name: 'Tên khách hàng',
  Customer_Last_Name: 'Họ khách hàng',
  Gender: 'Giới tính',
  Phone_Number: 'Số điện thoại',
  Email_Address: 'Địa chỉ Email',
  messageInvaldBir: 'Độ tuổi không phù hợp cho giới tính đã chọn',
  createdAt: 'Ngày tạo',
  nickName: 'Biệt danh',
  Birthday: 'Ngày sinh',
  Dateofissue: 'Ngày cấp',
  expirationDate: 'Ngày hết hạn',
  Placeofissue: 'Nơi cấp',
  passPort: 'Hộ chiếu',
  Description: 'Mô tả',
  addCustomer: ' Thêm KH Đại diện',
  addContactCustomer: 'Thêm hành khách Thành viên',
  addCustomerTitle: 'Thêm khách hàng đại diện',
  quantity: 'Số lượng',
  Street: 'Địa chỉ',
  emailAddress: 'Email',
  customerList: 'Danh sách khách hàng',
  errFetchData_1: 'Lỗi tìm nạp dữ liệu khách hàng',
  errFetchData_2: 'Không có khách hàng tìm thấy với ID của khách hàng này. Kiểm tra',
  errFetchData_3: 'cho khách hàng khác.',
  receivables: 'Doanh thu',
  noEmail: 'Không có email',
  profit: 'Lợi nhuận',
  fullName: 'Tên khách hàng',
  dateOfBirth: 'Ngày sinh',
  typeOfPassenger: 'Loại hành khách',
  Action: 'Hành động',
  balanceStartDate: 'Lợi nhuận theo thời gian ',
  show_details: 'Hiển thị chi tiết',
  view: 'Hiển thị',
  addContact: 'Thêm liên hệ',
  nationality: 'Quốc tịch',
  editContact: 'Cập nhật thông tin liên hệ',
  editCustomer: 'chỉnh sửa hành khách thành công',
  noInformation: 'Không có thông tin',
  noPhoneNumber: 'Không có số điện thoại',
  errFetchContact: 'Lỗi tìm nạp dữ liệu liên hệ',
  noContact_1: 'Không tìm thấy liên hệ với ID này. Kiểm tra ',
  noContact_2: 'Danh sách liên hệ',
  noContact_3: 'để thử lại.',
  delete: 'Xóa',
  details: 'Chi tiết',
  detailsCustomer: 'Chi tiết khách hàng',
  detailsContact: 'Chi tiết hành khách',
  addCustomerDetails: 'Thêm KH thành viên ',
  moreDropdown: {
    title: 'Lựa chọn',
    add: 'Thêm mới khách hàng',
    import: 'Nhập từ Excel',
    export: 'Xuất ra Excel',
    delete: 'Xoá khách hàng',
  },
  customer: ' Khách hàng',
  successCustomer: 'Khách hàng đã cập nhật thành công',
  Account: 'Tài khoản',
  Contact: 'Liên hệ',
  historyBooking: 'Lịch sử đặt vé',
  noInfo: 'Không có thông tin',
  Back: 'Trở lại',
  accountInfoText: 'Thông tin tài khoản',
  lastName: 'Họ',
  placeholderLastName: 'Điền vào họ',
  firstName: 'Tên đệm & Tên',
  placeholderFirstName: 'Điền vào Tên đệm & Tên',
  email: 'Email',
  phoneNumber: 'Số điện thoại',
  placeholderPhoneNumber: 'Nhập số điện thoại',
  dob: 'Ngày sinh',
  placeholderDoB: 'Chọn ngày sinh',
  gender: 'Danh xưng',
  placeholderSelectGender: 'Chọn giới tính',
  backupPhoneNumber: 'Số điện thoại dự phòng',
  description: 'Mô tả',
  placeholderDescription: 'Nhập mô tả',
  address: 'Địa chỉ',
  country: 'Quốc gia',
  placeholderCountry: 'Chọn quốc gia',
  loadingCountryText: 'Đang tải danh sách quốc gia ...',
  noResultFund: 'Không tìm thấy kết quả cho',
  noOptions: 'Xin lỗi, không có tùy chọn phù hợp.',
  city: 'Tỉnh/Thành phố',
  placeholderCity: 'Chọn Thành phố',
  loadingCityText: 'Đang tải danh sách thành phố...',
  district: 'Quận',
  placeholderDistrict: 'Chọn Quận',
  loadingDistrictText: 'Đang tải danh sách Quận...',
  ward: 'Phường',
  placeholderWard: 'Chọn Phường',
  loadingWardText: 'Đang tải danh sách Phường...',
  street: 'Đường',
  placeholderStreet: 'Nhập vào tên đường',
  needSelect_1: 'Bạn cần chọn',
  needSelect_2: 'trước.',
  back: 'Trở lại',
  clear: 'Xoá',
  message: 'Độ tuổi không hợp lệ cho danh xưng đã chọn.',
  next: 'Tiếp tục',
  save: 'Lưu',
  placeholderEmail: 'Điền vào email',
  typePassPeople: {
    ADULT: 'Người lớn',
    CHILD: 'Trẻ em',
    INFANT: 'Trẻ Sơ sinh',
  },
  genderOptionsGenders: {
    MR: 'Ông',
    MRS: 'Bà',
    MS: 'Cô',
    MSTR: 'Bé trai',
    MISS: 'Bé gái',
  },
  genderOptionsGender: {
    Male: 'Nam',
    Female: 'Nữ',
    Neutral: 'Trung lập',
    Mistress: 'Em bé trai',
    Miss: 'Em bé gái',
  },
  note: 'Mô tả',
  contact: 'Liên hệ',
  addNew: 'Thêm mới',
  passengerType: 'Loại hành khách',
  contactInfoText: 'Thông tin liên hệ',
  information: 'Thông tin',
  noRecordFund: 'Không tìm thấy kết quả',
  placeholderSearchContact: 'Tên, email...',
  edit: 'Chỉnh sửa',
  updateCustomer: 'Chỉnh sửa khách hàng',
  updateContact: 'Chỉnh sửa hành khách',
  cancel: 'Huỷ',
  memberShip: 'Thẻ thành viên',
}
